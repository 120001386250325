<template>
  <div class="productIndex">
    <div class="pageTtlBlock">
      <h1 class="pageTtlBlock__ttl">
        PRODUCT
        <span>製品紹介</span>
      </h1>
    </div>
    <div class="inner">
      <ul class="productIndex__list">
        <li class="js-scroll">
          <router-link :to="{ name: 'Product01'}">
            <p class="productIndex__list__img">
              <img src="/genbashiko/img/product/index/image01.jpg" alt="" />
            </p>
            <p class="productIndex__list__name">
              自動外観検査
            </p>
            <p class="productIndex__list__txt">
              自動車製造工場の検査工程に特化した自動検査システムの構築では、コンサルティングを通じてセンシングデバイス、照明、画像処理システムが三位一体となった先進的なソリューションを提供しています。 
            </p>
          </router-link>
        </li>
        <li class="js-scroll">
          <router-link :to="{ name: 'Product02'}">
            <p class="productIndex__list__img">
              <img src="/genbashiko/img/product/index/image02.jpg" alt="" />
            </p>
            <p class="productIndex__list__name">
              外観検査用LED照明製品
            </p>
            <p class="productIndex__list__txt">
              株式会社Luci(ルーチ)では、自動車製造工場の検査工程で使用するのに最適な、オリジナルのLED照明製品を開発・製造しています。欠陥や不良を発見しやすく生産性の高い検査照明の構築をサポートします。 
            </p>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import scrollAnimMixin from "../../mixins/scrollAnimMixin.js";

export default {
  mixins: [scrollAnimMixin],
  head: {
  title: {
    inner: '製品紹介一覧'
  },
  meta: [
    { property: 'og:title', content: '製品紹介一覧｜GENBA SHIKO' },
    { name: 'description', content: '自動車製造工場の様々な課題に対して、自動検査(AI)や照明製品を用いて、車体品質の確保と業務効率化をサポートします。' },
    { property: 'og:description', content: '自動車製造工場の様々な課題に対して、自動検査(AI)や照明製品を用いて、車体品質の確保と業務効率化をサポートします。' },
    { property: 'og:url', content: 'https://luci.co.jp/genbashiko/product' },
    { property: 'og:type', content: 'article' }
    ]
  }
};
</script>

<style lang="scss" scoped>
.productIndex {
  .pageTtlBlock {
    &::after {
      background: url("/genbashiko/img/product/index/bg.jpg") center/cover no-repeat;
    }
  }
  &__list {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      flex: 0 0 510px;
      letter-spacing: 0.05em;
      &:hover {
        .productIndex__list__img {
          opacity: 0.7;
          transition: all 0.3s ease-out;
        }
      }
    }
    &__img {
      transition: all 0.3s ease-in;
    }
    &__name {
      font-size: 2.2rem;
      line-height: 1.4;
      margin-top: 30px;
      font-weight: bold;
    }
    &__txt {
      font-size: 1.8rem;
      margin-top: 30px;
      line-height: 1.7;
    }
  }
}
@media only screen and (max-width: 768px) {
  .productIndex {
    .pageTtlBlock {
      &::after {
        background: url("/genbashiko/img/product/index/bg-sp.jpg") center/cover no-repeat;
      }
    }
    &__list {
      margin-top: 40px;
      display: block;
      &__name {
        font-size: 1.4rem;
      }
      &__txt {
        font-size: 1.3rem;
        margin-top: 20px;
      }
      &__img{
        img{
          width:100%;
        }
      }
      li {
        + li {
          margin-top: 40px;
        }
      }
    }
  }
}
</style>
